import { Section, Button, H5, Anchor, Icon } from "@dnb/eufemia/";
import React, { useEffect } from "react";
import "../../css/main.css";
import { Logo } from "@dnb/eufemia/components";
import Person from "../images/icons/person.png";
import { hamburger, bell, person, chat, chevron_down } from "@dnb/eufemia/icons";
import ErrorStatus from "../Common/ErrorStatus";

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  return (
    <div className="Layout">
      <div className="Content-Wrap">
        <noscript>
          <div className="Content-Wrap__NoScriptWarning">
            Denne nettsiden krever JavaScript for å fungere. Vennligst endre
            innstillingene i din nettleser.
          </div>
        </noscript>
        <main className="Main">{children}</main>
      </div>
    </div>
  );
};

export default Layout;
