import { GlobalError } from '@dnb/eufemia/components'
import { graphql, PageProps } from "gatsby"
import React from "react"
import Layout  from "../components/Layout/Layout"



type DataProps = {
  site: {
    buildTime: string
  }
}

const UsingTypescript: React.FC<PageProps<DataProps>> = ({ data, path }) => (
  <Layout>
    <GlobalError status="500" />
  </Layout>
)

export default UsingTypescript

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`